<template>
  <div class="flex flex-col bg-gray-200 min-h-screen-action-bar">
    <div class="flex flex-row-reverse px-5 py-2 bg-white">
      <IconLink @click="resetMessageModal=true" icon-name="trash-can-outline"/>
    </div>
    <div class="flex-col bg-white mt-3 pb-4 flex-grow">
      <div class="text-gray-800 text-xl p-2 pl-3">{{ $t('messageEditor') }}</div>
      <div class="flex items-center justify-center w-full mt-3">
        <div class="w-1/2">
          <div class="mt-10">
            <div class="">
              <TextField v-model="newMessage.title" :label="$t('title')"/>
              <TextArea v-model="newMessage.content" :label="$t('content')"/>
              <MultiSelectField
                class="relative z-41"
                v-model="newMessage.instances"
                :options="availableInstances"
                :label="$tc('instance', 0)"
                :labelSingular="$tc('instance', 1)"
                @input="onInstanceSelect()"
              />
              <MultiSelectField
                class="relative z-40"
                :class="{'pointer-events-none': !isInstanceSelected && !isBranchSelected}"
                v-model="newMessage.branches"
                :disabled="!isInstanceSelected && !isBranchSelected"
                :options="availableBranches"
                :label="$tc('branch', 0)"
                :labelSingular="$tc('branch', 1)"
              />
              <div class="flex relative z-30">
                <DatePicker v-model="newMessage.fromDate" icon="calendar-multiselect" :label="$t('from')" top-panel/>
                <TextField v-model="newMessage.fromTime" type="time" class="ml-4" :label="$t('timeLong')"/>
              </div>
              <div class="flex relative z-20">
                <DatePicker v-model="newMessage.toDate" icon="calendar-multiselect" :label="$t('to')" top-panel/>
                <TextField  v-model="newMessage.toTime" type="time" class="ml-4" :label="$t('timeLong')"/>
              </div>
              <div class="flex flex-row-reverse">
                <Button @click="reallyWantToSendModal=true" :disabled="!messageDataComplete" class="ml-4" name="send" primary>
                  {{ $t('send') }}
                </Button>
                <Button @click="cancelMessageModal=true" name="cancel">
                  {{ $t('cancel') }}
                </Button>
              </div>
              <DialogModal
                v-model="resetMessageModal"
                :primary-text="$t('reallyWantToResetMessage')"
                :secondary-text="$t('unsavedDataIsLost')"
                @cancel="resetMessageModal=false"
                @approve="resetMessage()"
              />
              <DialogModal
                v-model="cancelMessageModal"
                :primary-text="$t('reallyWantToCancelMessage')"
                :secondary-text="$t('unsavedDataIsLost')"
                @cancel="cancelMessageModal=false"
                @approve="cancelMessage()"
              />
              <DialogModal
                v-model="reallyWantToSendModal"
                :primary-text="$t('reallyWantToSendMessage')"
                :secondary-text="`${selectedBranchesCount}
                  ${$tc('branch', selectedBranchesCount)} ${$tc('isAddressed', selectedBranchesCount)}`"
                @cancel="reallyWantToSendModal=false"
                @approve="sendMessage()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import IconLink from '../../components/IconLink.vue';
import DialogModal from '../../components/DialogModal.vue';

export default {
  components: { IconLink, DialogModal },
  data() {
    return {
      resetMessageModal: false,
      cancelMessageModal: false,
      reallyWantToSendModal: false,
    };
  },
  computed: {
    newMessage: {
      get() {
        return this.$store.state.messageEditor.newMessage;
      },
      set(val) {
        this.$store.commit('setNewMessage', val);
      },
    },
    availableInstances() {
      return this.$store.state.messageEditor.availableInstances;
    },
    isInstanceSelected() {
      return this.newMessage.instances.length > 0;
    },
    isBranchSelected() {
      return this.newMessage.branches.length > 0;
    },
    branchesLoaded() {
      return this.$store.state.messageEditor.branchesLoaded;
    },
    availableBranchesRaw() {
      return this.$store.state.messageEditor.availableBranches;
    },
    selectedBranchesCount() {
      return this.newMessage.branches.length;
    },
    availableBranches() {
      if (this.branchesLoaded) {
        return this.createAvailableBranchList();
      }
      return [];
    },
    messageDataComplete() {
      return Object.keys(this.newMessage).filter((key) => this.newMessage[key]?.length > 0).length === 8;
    },
  },
  methods: {
    sendMessage() {
      this.$store.dispatch('sendMessage');
      this.reallyWantToSendModal = false;
    },
    resetMessage() {
      this.$store.dispatch('resetMessage');
      this.resetMessageModal = false;
    },
    cancelMessage() {
      this.$store.dispatch('resetMessage');
      this.$router.push({ name: 'apps' });
      this.cancelMessageModal = false;
    },
    onInstanceSelect() {
      const selectedInstances = this.newMessage.instances;
      if (selectedInstances.length > 0) {
        this.$store.dispatch('getBranches', selectedInstances[selectedInstances.length - 1]);
      } else {
        this.newMessage.branches = [];
      }
      if (this.newMessage.branches.length > 0) {
        this.newMessage.branches = [];
      }
    },
    createAvailableBranchList() {
      if (this.isInstanceSelected) {
        const availableBranches = [];
        let id = 0;
        this.newMessage.instances.forEach((i) => {
          availableBranches.push({ // set block name
            blockTitle: true,
            block: i,
            name: i,
            branchId: null,
            id,
          });
          if (this.availableBranchesRaw[i]?.length > 0) {
            this.availableBranchesRaw[i].forEach((b) => {
              availableBranches.push({ // set values
                blockTitle: false,
                block: i,
                name: b.name,
                branchId: b.id,
                id,
              });
              id += 1;
            });
          }
          id += 1;
        });
        return availableBranches;
      }
      return [];
    },
  },
  mounted() {
    this.$store.dispatch('getInstances');
  },
};
</script>

<style scoped>

 .z-41 {
   z-index: 41;
 }
</style>
